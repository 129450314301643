import './App.less';
import { WalletConnectorProvider } from './wallet';
import { ConfigProvider } from './wallet/config';
import { ConnectionProvider } from './utils/connection';
import Routes from './routes';

function App() {
  return (
    <>
      <ConnectionProvider>
        <ConfigProvider>
          <WalletConnectorProvider>
            <Routes />
          </WalletConnectorProvider>
        </ConfigProvider>
      </ConnectionProvider>

      <div className="App"></div>
    </>
  );
}

export default App;
