import { Table } from 'antd';
import { useMarket } from '../../utils/markets';
import useTrades from '../../utils/useTrades';
import './index.less';

const Trades = () => {
  const data = useTrades();
  console.log('datadatadatadata', data);

  const { baseCurrency, quoteCurrency } = useMarket();

  const columns = [
    {
      title: `Price(${quoteCurrency === 'UNKNOWN' ? '?' : quoteCurrency})`,
      dataIndex: 'price',
      key: 'price',
      render: (price, record) => {
        const { side } = record;
        return <span className={side}>{price}</span>;
      },
    },
    {
      title: `Amount(${baseCurrency === 'UNKNOWN' ? '?' : baseCurrency})`,
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (time) => {
        return new Date(time).toLocaleTimeString('en-US', {
          hourCycle: 'h23',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      },
    },
  ];

  return (
    <>
      <div className="trades">
        <div className="title">Trades</div>
        <div className="overflow">
          <Table dataSource={data} columns={columns} pagination={false}></Table>
        </div>
      </div>
    </>
  );
};
export default Trades;
