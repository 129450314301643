import { useConnectedWallet } from '@saberhq/use-solana';
import { Button, Popconfirm } from 'antd';
import { useSendConnection } from '../../utils/connection';
import {
  getSelectedTokenAccountForMint,
  useBalances,
  useMarket,
  useSelectedOpenOrdersAccount,
  useTokenAccounts,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import { settleFunds } from '../../utils/send';

const SettleFunds = () => {
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const [accounts] = useTokenAccounts();
  const sendConnection = useSendConnection();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);
  const wallet = useConnectedWallet();
  const balances = useBalances();

  const handleSettle = () => {
    const baseCurrencyAccount = getSelectedTokenAccountForMint(
      accounts,
      market?.baseMintAddress
    );
    const quoteCurrencyAccount = getSelectedTokenAccountForMint(
      accounts,
      market?.quoteMintAddress
    );
    if (
      !openOrdersAccount ||
      !market ||
      !baseCurrencyAccount ||
      !quoteCurrencyAccount
    )
      return;

    try {
      settleFunds({
        market,
        openOrders: openOrdersAccount,
        connection: sendConnection,
        wallet,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        usdcRef: undefined,
        usdtRef: undefined,
      });
    } catch (e: any) {
      console.warn(e);
      notify({
        message: 'Error settling funds',
        description: e.message,
        type: 'error',
      });
    }
  };

  return (
    <>
      <div className="summary-title">
        <span>Unsettled Balance</span>
      </div>
      <div className="summary-item">
        <span>{baseCurrency}</span>
        <span>
          {balances[0]?.unsettled || 0}{' '}
          <span
            className="currency-fixed-length"
            // style={{ width: 30, display: 'inline-block', textAlign: 'right' }}
          ></span>
          {baseCurrency}
        </span>
      </div>
      <div className="summary-item">
        <span>{quoteCurrency}</span>
        <span>
          {balances[1]?.unsettled || 0}{' '}
          <span
            className="currency-fixed-length"
            // style={{ width: 30, display: 'inline-block', textAlign: 'right' }}
          ></span>
          {quoteCurrency}
        </span>
      </div>
      <div style={{ textAlign: 'center', paddingTop: 30 }}>
        {
          <Popconfirm
            title="Are you sure to proceed?"
            onConfirm={() => {
              handleSettle();
            }}
          >
            <Button
              disabled={
                !((balances[0]?.unsettled ? balances[0]?.unsettled > 0 : false) ||
                (balances[1]?.unsettled ? balances[1]?.unsettled > 0 : false))
              }
            >
              Settle funds
            </Button>
          </Popconfirm>
        }
      </div>
    </>
  );
};

export default SettleFunds;
