import { useState } from 'react';
import { useMarket } from './markets';
import { useInterval } from './useInterval';

const RECENT_TRADES_LIMIT = 20;
const RECENT_TRADES_API = 'https://serum-api-dev-ztbl.ue1-eks-0.prod-czff.zettablock.dev/serum/trades/'
  // process.env.NODE_ENV !== 'development'
  //   ? `${process.env.REACT_APP_BASE_URL}/trades/`
  //   : '/trades/';

const fetchTrades = async (market: string, limit = RECENT_TRADES_LIMIT) => {
  try {
    const data = await fetch(RECENT_TRADES_API + market + '?limit=' + limit);
    return await data.json();
  } catch (error) {
    return [];
  }
};

const useTrades = () => {
  const [trades, setTrades] = useState<any[]>([]);
  const marketInfo = useMarket();

  console.log('useMarketInfosuseMarketInfosuseMarketInfosuseMarketInfos');

  useInterval(() => {
    if (!marketInfo) return;
    const { name } = marketInfo;
    if (!name) return;
    fetchTrades(name.split('/').join('-')).then((res) => {
      setTrades(res);
      console.log(
        'useMarketInfosuseMarketInfosuseMarketInfosuseMarketInfos',
        res
      );
    });
  }, 2000);

  return trades;
};

export default useTrades;
