import { useConnectedWallet } from '@saberhq/use-solana';
import { Tabs, Table, Popconfirm, Tag, Button } from 'antd';
import { useSendConnection } from '../../utils/connection';
import {
  getSelectedTokenAccountForMint,
  useBalances,
  useFills,
  useMarket,
  useOpenOrders,
  useSelectedOpenOrdersAccount,
  useTokenAccounts,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import { cancelOrder, settleFunds } from '../../utils/send';
import './index.less';

const { TabPane } = Tabs;

const OpenOrders = () => {
  const openOrders = useOpenOrders();
  const filledOrders = useFills();
  console.log('filledOrdersfilledOrders', filledOrders);
  const sendConnection = useSendConnection();
  const wallet = useConnectedWallet();
  const balances = useBalances();
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const [accounts] = useTokenAccounts();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);

  const handleSettle = () => {
    const baseCurrencyAccount = getSelectedTokenAccountForMint(
      accounts,
      market?.baseMintAddress
    );
    const quoteCurrencyAccount = getSelectedTokenAccountForMint(
      accounts,
      market?.quoteMintAddress
    );
    if (
      !openOrdersAccount ||
      !market ||
      !baseCurrencyAccount ||
      !quoteCurrencyAccount
    )
      return;

    try {
      settleFunds({
        market,
        openOrders: openOrdersAccount,
        connection: sendConnection,
        wallet,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        usdcRef: undefined,
        usdtRef: undefined,
      });
    } catch (e: any) {
      console.warn(e);
      notify({
        message: 'Error settling funds',
        description: e.message,
        type: 'error',
      });
    }
  };

  if (!wallet)
    return (
      <div className="no-wallet-message">
        Please connect your wallet to see your open orders.
      </div>
    );

  const columns2 = [
    {
      title: 'Market',
      dataIndex: 'marketName',
      key: 'marketName',
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      render: (side) => (
        <Tag
          color={side === 'buy' ? '#41C77A' : '#F23B69'}
          style={{ fontWeight: 700 }}
        >
          {side.charAt(0).toUpperCase() + side.slice(1)}
        </Tag>
      ),
    },
    {
      title: `Size`,
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: `Price`,
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: `Liquidity`,
      dataIndex: 'liquidity',
      key: 'liquidity',
    },
    {
      title: 'Fees',
      dataIndex: 'feeCost',
      key: 'feeCost',
      render: (feeCost: number) => {
        return Math.abs(feeCost);
      },
    },
  ];
  const columns = [
    {
      tilte: 'Market',
      dataIndex: 'marketName',
      key: 'marketName',
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      render: (item: any) => {
        return <span className={item}>{item}</span>;
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    // {
    //   title: 'Filled(BTC)',
    //   dataIndex: 'filled',
    //   key: 'filled',
    // },
    {
      title: 'Price(USD)',
      dataIndex: 'price',
      key: 'price',
    },
    // {
    //   title: 'Fee(USD)',
    //   dataIndex: 'fee',
    //   key: 'fee',
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    // },
    {
      title: '',
      dataIndex: 'action',
      render: (_, openOrder) => {
        return (
          <Popconfirm
            title="Are you sure to cancel this order?"
            onConfirm={() => {
              console.log('cancel order', openOrder);
              try {
                cancelOrder({
                  market: openOrder.market,
                  connection: sendConnection,
                  wallet: wallet,
                  order: openOrder,
                });
              } catch (e: any) {
                console.warn(e);
                notify({
                  message: 'Error canceling order',
                  description: e.message,
                  type: 'error',
                });
              }
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button>Cancel Order</Button>
          </Popconfirm>
        );
      },
    },
  ];

  const columns3 = [
    {
      tilte: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Unsettled',
      dataIndex: 'unsettled',
      key: 'unsettled',
    },
    {
      title: 'Action',
      render: (_, record, index) => {
        console.log('aasdasdaasasdasdasd', record);
        return record.unsettled ? (
          <Popconfirm
            title="Are you sure to proceed?"
            onConfirm={() => {
              handleSettle();
            }}
          >
            <Button>Settle funds</Button>
          </Popconfirm>
        ) : (
          <Button disabled>Settle funds</Button>
        );
      },
    },
  ];
  const balancesData = [
    {
      coin: baseCurrency,
      balance: balances[0]?.wallet || 0,
      unsettled: balances[0]?.unsettled || 0,
    },
    {
      coin: quoteCurrency,
      balance: balances[1]?.wallet || 0,
      unsettled: balances[1]?.unsettled || 0,
    },
  ];

  return (
    <Tabs className="orders">
      <TabPane tab="Open Orders" key="1">
        <Table
          className="table"
          pagination={false}
          dataSource={openOrders || []}
          columns={columns}
        ></Table>
      </TabPane>
      <TabPane tab="Order History" key="2">
        <Table
          className="table"
          pagination={false}
          dataSource={filledOrders || []}
          columns={columns2}
        ></Table>
      </TabPane>
      <TabPane tab="Balance" key="3">
        <Table
          className="table"
          pagination={false}
          dataSource={balancesData || []}
          columns={columns3}
        ></Table>
      </TabPane>
    </Tabs>
  );
};

export default OpenOrders;
