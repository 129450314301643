import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { memo, useRef, useState } from 'react';
import { useMarket, useMarkPrice, useOrderbook } from '../../utils/markets';
import { useInterval } from '../../utils/useInterval';
import usePrevious from '../../utils/usePrevious';
import { getDecimalCount, isEqual } from '../../utils/utils';
import './index.less';

const generateGreenRows = (data?) => {
  if (!data) return '...';
  return data.map(
    (
      {
        price,
        size,
        total,
        sizePercent,
      }: {
        price: number;
        size: number;
        total: string;
        sizePercent: number;
      },
      index: number
    ) => {
      return (
        <Row key={index} className="green">
          <div
            className="percentage"
            style={{
              width: `${sizePercent}%`,
            }}
          ></div>
          <Col span="8">{price}</Col>
          <Col span="8">{size}</Col>
          <Col span="8">
            <div>{total}</div>
          </Col>
        </Row>
      );
    }
  );
};
const generateRedRows = (data?) => {
  if (!data) return '...';
  return data.map(
    (
      {
        price,
        size,
        total,
        sizePercent,
      }: {
        price: number;
        size: number;
        total: string;
        sizePercent: number;
      },
      index: number
    ) => {
      return (
        <Row key={index} className="red">
          <div
            className="percentage"
            style={{
              width: `${sizePercent}%`,
            }}
          ></div>
          <Col span="8">{price}</Col>
          <Col span="8">{size}</Col>
          <Col span="8">
            <div>{total}</div>
          </Col>
        </Row>
      );
    }
  );
};
const OrderBook = () => {
  const markPrice = useMarkPrice();
  const [orderbook] = useOrderbook();
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const currentOrderbookData = useRef<any>(null);
  const lastOrderbookData = useRef(null);
  const [selected, setSelected] = useState('all');

  const [orderbookData, setOrderbookData] = useState<any>(null);

  function getCumulativeOrderbookSide(
    orders,
    totalSize,
    backwards = false,
    count = 7
  ) {
    let cumulative = orders
      .slice(0, count)
      .reduce((cumulative, [price, size], i) => {
        const cumulativeSize = (cumulative[i - 1]?.cumulativeSize || 0) + size;
        cumulative.push({
          price: price.toFixed(getDecimalCount(market?.tickSize)),
          size: size.toFixed(getDecimalCount(market?.tickSize)),
          cumulativeSize,
          total: (price * size).toFixed(2),
          sizePercent: Math.round((cumulativeSize / (totalSize || 1)) * 100),
        });
        return cumulative;
      }, []);
    if (backwards) {
      cumulative = cumulative.reverse();
    }
    return cumulative;
  }

  console.log('marketmarketmarketmarketmarketmarketmarketmarket', market);

  useInterval(() => {
    if (
      !currentOrderbookData.current ||
      JSON.stringify(currentOrderbookData.current) !==
        JSON.stringify(lastOrderbookData.current)
    ) {
      console.log('orderbook bids', orderbook.bids);
      console.log('orderbook asks', orderbook.asks);

      let bids = orderbook?.bids || [];
      let asks = orderbook?.asks || [];

      let totalSize = 0;
      asks.forEach(([, size]) => {
        totalSize += size;
      });
      bids.forEach(([, size]) => {
        totalSize += size;
      });
      console.log('total is: ', totalSize);

      const count = selected === 'all' ? 7 : 15;
      let bidsToDisplay = getCumulativeOrderbookSide(
        bids,
        totalSize,
        false,
        count
      );
      let asksToDisplay = getCumulativeOrderbookSide(
        asks,
        totalSize,
        true,
        count
      );

      currentOrderbookData.current = {
        bids: orderbook?.bids,
        asks: orderbook?.asks,
      };

      setOrderbookData({ bids: bidsToDisplay, asks: asksToDisplay });
    }
  }, 250);

  return (
    <div className="order-book">
      <div className="title">Order Book</div>
      <div className="container">
        <div className="filter">
          <img
            onClick={() => {
              setSelected('all');
            }}
            className={selected === 'all' ? 'selected' : ''}
            width="20"
            src="https://trade.dexlab.space/static/media/orderbook-all.4104cda7.svg"
            alt=""
          />
          <img
            onClick={() => {
              setSelected('buy');
            }}
            className={selected === 'buy' ? 'selected' : ''}
            width="20"
            src="https://trade.dexlab.space/static/media/orderbook-buy-icon.029375d1.svg"
            alt=""
          />
          <img
            onClick={() => {
              setSelected('sell');
            }}
            className={selected === 'sell' ? 'selected' : ''}
            width="20"
            src="https://trade.dexlab.space/static/media/orderbook-sell-icon.b4d742c5.svg"
            alt=""
          />
        </div>
        <Row className="title">
          <Col span="8">
            Price ({quoteCurrency === 'UNKNOWN' ? '?' : quoteCurrency})
          </Col>
          <Col span="8">
            Amount ({baseCurrency === 'UNKNOWN' ? '?' : baseCurrency})
          </Col>
          <Col span="8">Total</Col>
        </Row>
        {selected !== 'buy' && (
          <div className="content">{generateRedRows(orderbookData?.asks)}</div>
        )}
        <div className="current-price">
          <MarkPriceComponent markPrice={markPrice} />
        </div>
        {selected !== 'sell' && (
          <div className="content">
            {generateGreenRows(orderbookData?.bids)}
          </div>
        )}
      </div>
    </div>
  );
};
export const MarkPriceComponent = memo(
  ({ markPrice }: any) => {
    const { market } = useMarket();
    const previousMarkPrice: number = usePrevious(markPrice)!;

    console.log('previousMarkPrice', markPrice);
    console.log('previousMarkPrice', previousMarkPrice);

    let markPriceColor =
      markPrice > previousMarkPrice
        ? '#42B47F'
        : markPrice < previousMarkPrice
        ? '#DB4E4E'
        : 'white';

    let formattedMarkPrice =
      markPrice && market?.tickSize && markPrice.toFixed(5);

    return (
      <Col style={{ color: markPriceColor }}>
        {markPrice > previousMarkPrice && (
          <ArrowUpOutlined style={{ marginRight: 5 }} />
        )}
        {markPrice < previousMarkPrice && (
          <ArrowDownOutlined style={{ marginRight: 5 }} />
        )}
        {formattedMarkPrice || '-- -- '}
      </Col>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['markPrice'])
);

export default OrderBook;
