import { DownOutlined } from '@ant-design/icons';
import './index.less';

const ChartHeader = ({ toggleMarketList, currentMarket }) => {
  if (currentMarket === null) {
  }
  return (
    <>
      <div className="chart-header desktop">
        <div className="market-symbol" onClick={toggleMarketList}>
          <span>{/* <StarOutlined style={{ fontSize: 24 }} /> */}</span>
          <span>{currentMarket?.symbol}</span>
          <span>
            <DownOutlined style={{ fontSize: 12, marginLeft: 10 }} />
          </span>
        </div>
        <div className="market-price">
          <div>
            <span>{`$${currentMarket?.price}`}</span>
          </div>
        </div>
        <div className="day-change info">
          <div>
            <span className="title">24h Change</span>
          </div>
          <div className="content">
            <span>
              {currentMarket?.change > 0
                ? `+${currentMarket?.change}`
                : currentMarket?.change}
              %
            </span>
          </div>
        </div>
        <div className="day-high info">
          <div className="title">
            <span>24h High</span>
          </div>
          <div>
            <span>{`$${currentMarket?.high}`}</span>
          </div>
        </div>
        <div className="day-low info">
          <div className="title">
            <span className="title">24h Low</span>
          </div>
          <div>
            <span>{`$${currentMarket?.low}`}</span>
          </div>
        </div>
        <div className="day-volume info">
          <div className="title">
            <span className="title">24h Volume (BTC)</span>
          </div>
          <div>
            <span>{currentMarket?.volume}</span>
          </div>
        </div>
      </div>
      <div className="chart-header-mobile" onClick={toggleMarketList}>
        <span className="title">{currentMarket?.symbol}</span>
        <div className="column">
          <span className="current-price"> {`$${currentMarket?.price}`}</span>
          <span
            className={`percentage ${
              currentMarket?.change > 0 ? 'up' : 'down'
            }`}
          >
            {currentMarket?.change > 0
              ? `+${currentMarket?.change}`
              : currentMarket?.change}
            %
          </span>
        </div>
        <div className="column">
          <span>24hr High</span>
          <span className="high">{`$${currentMarket?.high}`}</span>
        </div>
        <div className="column">
          {/* <span>24hr Volume</span> */}
          <span className="vol">{`$${currentMarket?.volume}`}</span>
        </div>
      </div>
    </>
  );
};

export default ChartHeader;
