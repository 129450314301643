import { CloseCircleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import ChartHeader from '../components/chartHeader';
import MarketList from '../components/marketList';
import MobileBalance from '../components/mobileBalance';
import NavBar from '../components/navbar';
import OpenOrders from '../components/openOrders';
import OrderBook from '../components/orderBook';
import OrderEntry from '../components/orderEntry';
import Trades from '../components/trades';
import { TVChartContainer } from '../components/tradingView';
import { MarketInfoProvider } from '../contexts/market.context';
import { MarketProvider } from '../utils/markets';
import './TradingPage.less';

const { TabPane } = Tabs;

const TradingPage = (props) => {
  const params = useParams<any>();
  const history = useHistory<any>();
  const [marketAddress, setMarketAddress] = useState(
    params?.marketAddress || 'ByRys5tuUWDgL73G8JBAEfkdFf8JWBzPBDHsBVQ5vbQA'
  );
  const [currentMarket, setCurrentMarket] = useState<any>(null);

  const [tab, setTab] = useState('chart');

  const setMarket = (marketAddress: string, marketInfo: any) => {
    setMarketAddress(marketAddress);
    if (marketInfo) {
      setCurrentMarket(marketInfo);
    }

    console.log('marketAddress', marketAddress);
    history.push(`/${marketAddress}`);
  };

  const [showMarketList, setShowMarketList] = useState(false);
  const [showOrderEntry, setShowOrderEntry] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [orderType, setOrderType] = useState<string>();

  const handleButtonClick = (orderType: string) => {
    setShowOrderEntry(true);
    setOrderType(orderType);
  };

  const toggleMarketList = () => {
    setShowMarketList(!showMarketList);
  };

  useEffect(() => {
    if (currentMarket === null) {
      const fetchDefaultMarket = async () => {
        const res = await fetch(
          // process.env.NODE_ENV !== 'development'
          //   ? `${process.env.REACT_APP_BASE_URL}/quote?symbol=SRM%2FUSDC`
          //   : '/quote?symbol=SRM%2FUSDC'
          'https://serum-api-dev-ztbl.ue1-eks-0.prod-czff.zettablock.dev/serum/quote?symbol=SRM%2FUSDC'
        );
        const data = await res.json();
        setCurrentMarket(data);
      };
      fetchDefaultMarket();
    }
  }, [currentMarket]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      console.log('width', width);
      setScreenSize(width);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!params.marketAddress) {
    return <Redirect to="/ByRys5tuUWDgL73G8JBAEfkdFf8JWBzPBDHsBVQ5vbQA" />;
  }

  return (
    <>
      <div className="mobile">
        <Card title="ZettaTrade" style={{ width: 300 }}>
          <p>
            ZettaTrade currently only supports trading on desktop devices. Please try
            using a desktop device instead.
          </p>
        </Card>
      </div>
      <div className="page-contaienr">
        <MarketProvider
          marketAddress={marketAddress}
          setMarketAddress={setMarket}
        >
          <NavBar />
          <Row className="desktop-view">
            <Col md={14} xs={24}>
              <Row
                style={{
                  height: 'calc(100vh - 55px)',
                  borderRight: '1px solid #262626',
                }}
              >
                <Col span="24">
                  <ChartHeader
                    toggleMarketList={toggleMarketList}
                    currentMarket={currentMarket}
                  />
                  {screenSize > 768 && renderChart(currentMarket?.symbol)}
                  <OpenOrders />
                </Col>
              </Row>
            </Col>
            <Col className="height-col" sm={5} xs={24}>
              <OrderBook />
              <Trades />
            </Col>
            <Col sm={5} xs={24}>
              <MarketInfoProvider>
                <OrderEntry />
              </MarketInfoProvider>
            </Col>
          </Row>

          <Row className="mobile-view">
            <Col span={24}>
              <ChartHeader
                toggleMarketList={toggleMarketList}
                currentMarket={currentMarket}
              />
            </Col>
            <Col span={24}>
              <Tabs
                centered
                defaultActiveKey={tab}
                onChange={(tab) => {
                  setTab(tab);
                }}
              >
                <TabPane tab="Chart" key="chart" style={{ height: '50vh' }}>
                  <div style={{ padding: '10px', height: '100%' }}>
                    {screenSize <= 768 && renderChart(currentMarket?.symbol)}
                  </div>
                </TabPane>
                <TabPane
                  tab="Orderbook"
                  key="orderBook"
                  style={{ height: '50vh' }}
                >
                  <OrderBook />
                </TabPane>
                <TabPane tab="Trades" key="trades" style={{ height: '50vh' }}>
                  <Trades />
                </TabPane>
              </Tabs>
            </Col>
            <Col span={24}>
              <OpenOrders />
            </Col>
            <Col span={24} style={{ paddingBottom: '100px' }}>
              <MobileBalance clickCallback={handleButtonClick} />
            </Col>
          </Row>
          {/* Market List */}
          <div className={`market-list ${showMarketList && 'display'}`}>
            <div className='overlay'></div>
            <MarketList
              setMarketAddress={setMarket}
              toggleMarketList={toggleMarketList}
              showMarketList={showMarketList}
            />
          </div>
          <div
            className={`mobile-order-entry ${showOrderEntry ? 'active' : ''}`}
          >
            <div
              className="back"
              onClick={() => {
                setShowOrderEntry(false);
              }}
            >
              <CloseCircleOutlined
                style={{ fontSize: 20, color: 'rgba(255,255,255,0.8)' }}
              />
            </div>
            <MarketInfoProvider>
              {showOrderEntry && <OrderEntry orderType={orderType} />}
            </MarketInfoProvider>
          </div>
        </MarketProvider>
      </div>
    </>
  );
};

export default TradingPage;

export const renderChart = (symbol = 'SOL/USDC') => {
  console.log('symbolsymbolsymbolsymbolsymbol', symbol);
  return (
    <TVChartContainer
      key={symbol}
      symbol={symbol}
      datafeedUrl={
        // process.env.NODE_ENV !== 'development'
        //   ? `${process.env.REACT_APP_TRADING_VIEW_URL}`
        //   : 'https://serum-api.zettablock.com/serum'
        'https://serum-api.zettablock.com/serum'
      }
    />
  );
};
