import { useWalletKit, WalletKitProvider } from '@gokiprotocol/walletkit';
import type { Network } from '@saberhq/solana-contrib';
import type { ConnectedWallet, WalletProviderInfo } from '@saberhq/use-solana';
import React from 'react';
import { message } from 'antd';
import { useConfig } from './config';

const notify = ({
  msg,
  description,
}: {
  msg: string;
  description?: string;
}) => {
  message.info({
    content: (
      <div>
        <h3>{msg}</h3>
        {description}
      </div>
    ),
  });
};

export type ConnectWallet = () => void;

interface Props {
  children: React.ReactNode;
}

const onConnect = (wallet: ConnectedWallet, provider: WalletProviderInfo) => {
  const walletPublicKey = wallet.publicKey.toBase58();
  const keyToDisplay =
    walletPublicKey.length > 20
      ? `${walletPublicKey.substring(0, 7)}.....${walletPublicKey.substring(
          walletPublicKey.length - 7,
          walletPublicKey.length
        )}`
      : walletPublicKey;

  notify({
    msg: 'Wallet update',
    description: 'Connected to wallet ' + keyToDisplay,
  });
};

const onDisconnect = () => {
  notify({
    msg: 'Wallet disconnected',
  });
};

const onError = (err: Error) => {
  notify({
    msg: `Error connecting to wallet`,
    description: err.message,
  });
};

const defaultNetwork: Network =
  window.location.hostname === 'devnet.app.saber.so'
    ? 'devnet'
    : 'mainnet-beta';

console.log(defaultNetwork);

export const WalletConnectorProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const { environments } = useConfig();
  return (
    <WalletKitProvider
      defaultNetwork={defaultNetwork}
      networkConfigs={
        defaultNetwork !== 'mainnet-beta'
          ? { [defaultNetwork]: environments[defaultNetwork] }
          : environments
      }
      onConnect={onConnect}
      onDisconnect={onDisconnect}
      app={{
        name: 'ZettaTrade',
        icon: <div>ZettaTrade</div>,
      }}
      onError={onError}
    >
      {children}
    </WalletKitProvider>
  );
};

/**
 * Returns a function which shows the wallet selector modal.
 */
export const useConnectWallet = (): (() => void) => {
  const { connect } = useWalletKit();
  return connect;
};
