import type { Network } from '@saberhq/solana-contrib';
import { useMemo } from 'react';
import { createContainer } from 'unstated-next';

type EnvironmentsMap = { [N in Network]: IEnvironment };

interface UseConfig {
  environments: EnvironmentsMap;
}

export type IEnvironment = Readonly<{
  name: string;
  endpoint: string;
}>;

export const environmentsConfig = {
  'mainnet-beta': {
    name: 'Mainnet Beta',
    endpoint: 'http://michael.rpcpool.com',
  },
  devnet: {
    name: 'Devnet',
    // endpoint: `https://stage.devnet.rpcpool.com/${
    //   process.env.RPC_POOL_DEVNET_TOKEN ?? ''
    // }`,
    endpoint: 'https://api.devnet.solana.com/',
    // endpoint: "https://sg6.rpcpool.com/",
  },
  testnet: {
    name: 'Testnet',
    endpoint: 'https://api.testnet.solana.com/',
  },
  localnet: {
    name: 'Localnet',
    endpoint: 'http://localhost:8899/',
    exchanges: {},
  },
} as const;

export interface Flags {
  /**
   * Whether or not to use the RPC1 mainnet nodes.
   */
  'use-rpc1-nodes': boolean;
}

export const DEFAULT_FLAGS: Flags = {
  'use-rpc1-nodes': false,
};

const useConfigInternal = (): UseConfig => {
  const environments: EnvironmentsMap = useMemo(() => {
    return environmentsConfig;
  }, []);

  return { environments };
};

export const { Provider: ConfigProvider, useContainer: useConfig } =
  createContainer(useConfigInternal);
