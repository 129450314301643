import { useConnectedWallet } from '@saberhq/use-solana';
import {
  Button,
  Collapse,
  Input,
  Radio,
  RadioChangeEvent,
  Slider,
  Switch,
} from 'antd';
import { FormEvent, useState } from 'react';
import { useSendConnection } from '../../utils/connection';
import {
  useBalances,
  useMarket,
  useMarkPrice,
  useSelectedBaseCurrencyAccount,
  useSelectedQuoteCurrencyAccount,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import { placeOrder } from '../../utils/send';
import SettleFunds from '../settleFunds';

import './index.less';

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Panel } = Collapse;

const OrderEntry = ({ orderType = 'buy' }) => {
  const balances = useBalances();
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const { market } = useMarket();
  const wallet = useConnectedWallet();
  const sendConnection = useSendConnection();
  const markPrice = useMarkPrice();

  const [order, setOrder] = useState({
    orderType: orderType,
    priceType: 'limit',
    price: 0,
    amount: 0,
    total: 0,
    percentage: 0,
    extraOrderType: '',
    post: false,
    ioc: false,
  });

  const handleAmountChange = (event: FormEvent) => {
    const { value } = event.target as HTMLInputElement;
    const amount = parseFloat(value);
    setOrder({
      ...order,
      amount: amount,
    });
  };

  const handlePOSTChange = (val: boolean) => {
    setOrder({
      ...order,
      post: val,
      ioc: false,
    });
  };

  const handleIOCChange = (val: boolean) => {
    setOrder({
      ...order,
      post: false,
      ioc: val,
    });
  };

  const handleOrderTypeChange = (event: RadioChangeEvent) => {
    setOrder({ ...order, orderType: event.target.value });
  };

  const handlePriceTypeChange = (event: RadioChangeEvent) => {
    const { value: priceType } = event.target;
    if (priceType === order.priceType) return;
    if (priceType === 'market') {
      if (!markPrice) {
        window.alert('Market price not available');
        return;
      }
      setOrder({ ...order, price: markPrice, priceType });
    } else {
      setOrder({ ...order, priceType, price: 0 });
    }
  };

  const handlePriceChange = (event: FormEvent) => {
    const { value } = event.target as HTMLInputElement;
    const price = parseFloat(value);
    setOrder({
      ...order,
      price: price,
    });
  };
  const handleVolumeChange = (value: number) => {
    let newAmount = 0;
    if (balances[1] && balances[1].wallet && order.price !== 0) {
      newAmount = Math.floor((value * balances[1].wallet) / 100 / order.price);
    }
    setOrder({
      ...order,
      amount: newAmount,
    });
  };

  const handlePlaceOrder = () => {
    const orderParams = {
      side: order.orderType as 'buy' | 'sell',
      price: order.price,
      size: order.amount,
      orderType: order.post
        ? ('postOnly' as 'postOnly' | 'ioc' | 'limit')
        : order.ioc
        ? ('ioc' as 'postOnly' | 'ioc' | 'limit')
        : ('limit' as 'postOnly' | 'ioc' | 'limit'),
      market,
      connection: sendConnection,
      wallet,
      baseCurrencyAccount: baseCurrencyAccount?.pubkey,
      quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
      feeDiscountPubkey: undefined,
    };

    try {
      console.log('Placing order', orderParams);
      placeOrder(orderParams);
    } catch (e: any) {
      console.warn(e);
      notify({
        message: 'Error placing order',
        description: e.message,
        type: 'error',
      });
    }
  };

  return (
    <div className={`${order.orderType} order-entry`}>
      <div className="order-type">
        <RadioGroup value={order.orderType} onChange={handleOrderTypeChange}>
          <RadioButton value="buy">Buy</RadioButton>
          <RadioButton value="sell">Sell</RadioButton>
        </RadioGroup>
      </div>
      <div className="order-details">
        <div className="order-price">
          <RadioGroup value={order.priceType} onChange={handlePriceTypeChange}>
            <RadioButton value="limit">Limit</RadioButton>
            <RadioButton value="market">Market</RadioButton>
          </RadioGroup>

          {order.priceType === 'limit' && (
            <>
              <Input
                type="number"
                step={0.01}
                value={order.price}
                size="large"
                onChange={handlePriceChange}
                addonBefore={'Price'}
                addonAfter={
                  balances[1]?.coin ||
                  'UNK' /* TODO: Change to human readable code */
                }
              />
              {/* <div className="quick-select">
                <RadioGroup defaultValue="market">
                  <RadioButton value="mid">MID</RadioButton>
                  <RadioButton value="bid">BID</RadioButton>
                  <RadioButton value="one_percent_less">
                    1%
                    <ArrowDownOutlined />
                  </RadioButton>
                  <RadioButton value="five_percent_less">
                    5%
                    <ArrowDownOutlined />
                  </RadioButton>
                  <RadioButton value="ten_percent_less">
                    10%
                    <ArrowDownOutlined />
                  </RadioButton>
                </RadioGroup>
              </div> */}
            </>
          )}
          <Input
            onChange={handleAmountChange}
            type="number"
            addonBefore="Amount"
            size="large"
            value={order.amount}
            addonAfter={balances[0]?.coin || 'UNK'}
          />

          <div className="slider">
            <Slider
              onChange={handleVolumeChange}
              value={
                (balances[1]?.wallet &&
                  ((order.amount * order.price) / balances[1]?.wallet) * 100) ||
                0
              }
              step={1}
              marks={{
                0: '',
                25: '',
                50: '',
                75: '',
                100: '',
              }}
            />
          </div>
          <div className="total">
            <Input
              readOnly
              type="number"
              value={order.amount * order.price || 0}
              addonBefore="Total"
              size="large"
              addonAfter={balances[1]?.coin || 'UNK'}
            />
          </div>
          {/* <div className="fee">
            <span>EST.FEE</span>
            <span>
              {999} {state.market.market.split('-')[1]}
            </span>
          </div> */}
        </div>
        <div className="order-advanced">
          <Collapse expandIconPosition="right">
            <Panel header="Advanced" key="1">
              <div className="option-container">
                <div className="toggle-container">
                  <div className="title">POST ONLY</div>
                  <Switch checked={order.post} onChange={handlePOSTChange} />
                </div>
                <div className="toggle-container">
                  <div className="title">IMMEDIATE OR CANCEL</div>
                  <Switch checked={order.ioc} onChange={handleIOCChange} />
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="oder-action">
        <Button
          type="primary"
          onClick={() => {
            handlePlaceOrder();
          }}
        >
          {order.orderType === 'buy' ? 'Buy' : 'Sell'}{' '}
          {balances[0]?.coin || 'UNK'}
        </Button>
      </div>
      <div className="order-summary">
        <div className="wallet-balance">
          <div className="summary-title">
            <span>Wallet Balance</span>
          </div>
          <div className="summary-item">
            <span>{balances[0]?.coin || ''} </span>
            <span>
              {balances[0]?.wallet || '0'} {balances[0]?.coin || 'UNK'}
            </span>
          </div>
          <div className="summary-item">
            <span>{balances[1]?.coin || ''} </span>
            <span>
              {balances[1]?.wallet || '0'} {balances[1]?.coin || 'UNK'}{' '}
            </span>
          </div>
        </div>
        <div className="unsettled-balance">
          <SettleFunds />
        </div>
      </div>
    </div>
  );
};

export default OrderEntry;
