import * as React from 'react';
import './index.less';
import { widget } from '../../charting_library';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: 'AAPL',
    interval: 'D',
    containerId: 'tv_chart_container',
    datafeedUrl:
      'http://adf9a3be08197414dadb0951d25a2c78-150154469.us-east-1.elb.amazonaws.com:443/serum/bars',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  tvWidget = null;

  componentWillReceiveProps(prevProps) {
    console.log('componentDidUpdate', prevProps);
  }

  componentDidMount() {
    const widgetOptions = {
      symbol: this.props.symbol,
      // symbol: 'BTC/USDC',
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        this.props.datafeedUrl
      ),
      // interval: this.props.interval,
      supported_resolutions: ["1", "15", "240", "D", "6M"],
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      timeframe: '3d',
      interval: '60',
      time_frames: [
        { text: '1d', resolution: '15', description: '1 Day' },
        { text: '3d', resolution: '60', description: '3 Days' },
        { text: '7d', resolution: '60', description: '7 Days' },
        { text: '1m', resolution: '60', description: '1 Month' },
        { text: '3m', resolution: '60', description: '3 Months' },
      ],
      locale: getLanguageFromURL() || 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'legend_widget',
      ],
      // enabled_features: ['study_templates'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      toolbar_bg: '#000000',
      theme: 'Dark',
      header_symbol_search: false,
      custom_css_url: 'asdaafasfgsdfasd',
      background: '#000000',
      hide_legend: true,
      overrides: {
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#000000',
        'scalesProperties.backgroundColor': '#000000',
        'paneProperties.vertGridProperties.color': '#000',
        'mainSeriesProperties.candleStyle.bgColor': '#41C77A',
        'mainSeriesProperties.candleStyle.upColor': '#41C77A',
        'mainSeriesProperties.candleStyle.downColor': '#F23B69',
        'mainSeriesProperties.candleStyle.borderUpColor': '#41C77A',
        'mainSeriesProperties.candleStyle.borderDownColor': '#F23B69',
        'mainSeriesProperties.candleStyle.wickUpColor': '#41C77A',
        'mainSeriesProperties.candleStyle.wickDownColor': '#F23B69',
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.addCustomCSSFile('/joseph.css');
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () =>
          tvWidget.showNoticeDialog({
            title: 'Notification',
            body: 'TradingView Charting Library API works correctly',
            callback: () => {
              console.log('Noticed!');
            },
          })
        );

        button.innerHTML = 'Check API';
      });
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return <div id={this.props.containerId} className={'TVChartContainer'} />;
  }
}
