import { useConnectedWallet } from '@saberhq/use-solana';
import { useConnectWallet } from '../../wallet';
import './index.less';

const NavBar = () => {
  const connect = useConnectWallet();
  const wallet = useConnectedWallet();
  return (
    <header className="nav-bar">
      <div
        className="logo"
        style={{
          backgroundColor: '#666',
          width: '140px',
          height: 38,
        }}
      ></div>
      <div className="links">
        <div className="trade-link hide-mobile">Trade</div>
        {!wallet && (
          <div onClick={connect} className="wallet-link">
            Connect wallet
          </div>
        )}
      </div>
    </header>
  );
};

export default NavBar;
