import { useBalances, useMarket } from '../../utils/markets';

import './index.less';

const MobileBalance = ({clickCallback}) => {
  const { baseCurrency, quoteCurrency } = useMarket();
  const balances = useBalances();
  return (
    <div className="mobile-trade">
      <div className="balances">
        <div className="balance">
          <div className="title">
            <span>Balance</span>
          </div>
          <div className="base">
            {`${balances[0]?.wallet || 0} ${baseCurrency}`}
          </div>
          <div className="quote">
            {`${balances[1]?.wallet || 0} ${quoteCurrency}`}
          </div>
        </div>
        <div className="unsettled">
          <div className="title">Unsettled</div>
          <div className="base">
            {`${balances[0]?.unsettled || 0} ${baseCurrency}`}
          </div>
          <div className="quote">
            {`${balances[1]?.unsettled || 0} ${quoteCurrency}`}
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <div className="buy" onClick={() => {
          clickCallback('buy')
        }}>
          <span>Buy</span>
        </div>
        <div className="sell" onClick={() => {
          clickCallback('sell')
        }}>
          <span>Sell</span>
        </div>
        <div className="settle">
          <span>Settle</span>
        </div>
      </div>
    </div>
  );
};

export default MobileBalance;
