import { Button, Input, Table } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import './index.less';
import { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { useMarketsList } from '../../utils/markets';
interface Tab {
  name: string;
  id: string;
}

const TABS_LIST = [
  {
    name: 'All',
    id: '',
  },
  {
    name: 'SOL',
    id: 'SOL',
  },
  {
    name: 'USD',
    id: 'usd',
  },
  {
    name: 'BTC',
    id: 'btc',
  },
];

const renderMarketList = (
  selectedTab: string,
  AVAILABLE_MARKETS: any[],
  setMarketAddress: (address: string, info: any) => void,
  toggleMarketList: () => void,
  marketsList
) => {
  // TODO: get list of markets from API

  // mock data
  interface TradingData {
    market: string;
    key: string;
    volume: number;
    price: number;
    percentange: number;
    high24hr: number;
    address: string;
    change: string;
  }

  const columns: ColumnType<TradingData>[] = [
    {
      title: 'Market',
      dataIndex: 'symbol',
      // sorter: (a: TradingData, b: TradingData) =>
      //   a.market.localeCompare(b.market),
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      sorter: (a: TradingData, b: TradingData) => a.volume - b.volume,
      render: (volume: number) => {
        return <div>{volume}</div>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a: TradingData, b: TradingData) => a.price - b.price,
      render: (_: number, record: TradingData) => {
        const { price, change } = record;
        return (
          <div>
            <span>{price}</span>
            <br />
            <span
              className={`${
                parseFloat(change) > 0 ? 'percentange-up' : 'percentange-down'
              } percentage`}
            >
              {parseFloat(change) > 0 ? `+${change}` : `${change}`}%
            </span>
          </div>
        );
      },
    },
    {
      title: '24h High',
      dataIndex: 'high',
      sorter: (a: TradingData, b: TradingData) => a.high24hr - b.high24hr,
    },
  ];

  return (
    <div className="market-lists-container">
      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              console.log('recordrecord', record);
              setMarketAddress(record.address, record);
              toggleMarketList();
            },
          };
        }}
        pagination={false}
        columns={columns}
        dataSource={marketsList}
        scroll={{ y: 'calc(100vh - 200px' }}
      />
    </div>
  );
};

const renderTabs = (
  tabsList: Tab[],
  selectedTab: string,
  callback: React.Dispatch<string>
) => {
  return tabsList.map((tab) => {
    return (
      <Button
        className={'tab'}
        key={tab.id}
        type={selectedTab === tab.id ? 'primary' : 'default'}
        onClick={() => {
          callback(tab.id);
        }}
      >
        {tab.name}
      </Button>
    );
  });
};

const MarketList = ({ setMarketAddress, toggleMarketList, showMarketList }) => {
  const [selectedTab, setSelectedTab] = useState<string>('all');
  const AVAILABLE_MARKETS = useMarketsList();

  const [markets, setMarkets] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  const getAllMarkets = async () => {
    console.log('process.env: ', process.env);
    console.log('process.env.BASE_URL: ', process.env.BASE_URL);
    const quotes = await fetch(
      'https://serum-api-dev-ztbl.ue1-eks-0.prod-czff.zettablock.dev/serum/quotes'
      // process.env.NODE_ENV !== 'development'
      //   ? `${process.env.REACT_APP_BASE_URL}/quotes`
      //   : '/quotes'
    );
    const allMarkets = await quotes.json();
    console.log('all markets', allMarkets);
    const sortedMarketList = allMarkets.map((item) => {
      return {
        ...item,
        address:
          AVAILABLE_MARKETS.find((e) => {
            return e.name === item.symbol;
          })?.address.toBase58() || '',
      };
    });
    sortedMarketList.sort((a, b) => {
      return parseFloat(b.volume) - parseFloat(a.volume);
    });
    setMarkets(sortedMarketList);
  };
  useEffect(() => {
    getAllMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMarketList]);

  // .filter((e) => {
  //   return e.name.includes('SBR');
  // });
  console.log('AVAILABLE_MARKETS: ', AVAILABLE_MARKETS);
  AVAILABLE_MARKETS.forEach((e) =>
    console.log('Markets name', e.address.toBase58())
  );
  useEffect(() => {});
  return (
    <div className="market">
      <h1 onClick={toggleMarketList}>
        <span>Market</span>
        <span>
          {/* <CloseOutlined /> */}
          <CloseCircleOutlined />
        </span>
      </h1>
      {/* Sparklines */}
      {/* Search Bar */}
      <Input
        size="large"
        prefix={<SearchOutlined />}
        onChange={(e) => {
          console.log('keyword', e.target.value);
          setKeyword(e.target.value);
        }}
      />
      {/* Tabs */}
      <div className="tabs">
        {renderTabs(TABS_LIST, selectedTab, (e) => {
          setKeyword(e);
          setSelectedTab(e);
        })}
      </div>
      {/* List of Markets */}
      {renderMarketList(
        selectedTab,
        AVAILABLE_MARKETS,
        setMarketAddress,
        toggleMarketList,
        markets.filter((market) => {
          return market.symbol.toLowerCase().includes(keyword.toLowerCase());
          // ||
          // market.symbol.toLowerCase().includes(keyword.toLowerCase())
          // TODO: add a name field?
        })
      )}
    </div>
  );
};

export default MarketList;
