import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TradingPage from './pages/TradingPage';

const Routes = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path="/">
          <TradingPage
            params={{
              marketAddress: 'HXBi8YBwbh4TXF6PjVw81m8Z3Cc4WBofvauj5SBFdgUs',
            }}
          />
        </Route>
        <Route exact path="/:marketAddress">
          <TradingPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
