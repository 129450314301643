import { createContext, ReactNode, useContext, useReducer } from 'react';

const initialState = {
  market: {
    market: 'BTC-USD',
    marketAddress: '',
    price: 75,
    time: new Date(),
  },
};

type State = {
  market: {
    market: string;
    marketAddress: string;
    price: number;
    time: Date;
  };
};
type Dispatch = (action: { type: string; payload: any }) => void;

type MarketContextType = {
  state: State;
  dispatch: Dispatch;
};

const MarketContext = createContext<MarketContextType | undefined>(undefined);

const marketReducer = (state: any, action: { type: string; payload: any }) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_MARKET':
      return {
        ...state,
        market: payload,
      };
    default:
      return state;
  }
};

const MarketInfoProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(marketReducer, initialState);

  const value = {
    state,
    dispatch,
  };

  return (
    <MarketContext.Provider value={value}>{children}</MarketContext.Provider>
  );
};

const useMarketInfo = () => {
  const context = useContext(MarketContext);

  if (context === undefined) {
    throw new Error('useMarket must be used within a MarketProvider');
  }

  return context;
};

export { MarketInfoProvider, useMarketInfo };
