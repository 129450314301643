import * as BufferLayout from 'buffer-layout';

import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';

import { PublicKey } from '@solana/web3.js';

export const ACCOUNT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(32, 'mint'),
  BufferLayout.blob(32, 'owner'),
  BufferLayout.nu64('amount'),
  BufferLayout.blob(93),
]);
export async function getTokenAccountInfo(connection: any, ownerAddress: any) {
  let [splAccounts, account] = await Promise.all([
    getOwnedTokenAccounts(connection, ownerAddress),
    connection.getAccountInfo(ownerAddress),
  ]);
  const parsedSplAccounts: any = splAccounts.map(
    ({ publicKey, accountInfo }) => {
      return {
        pubkey: publicKey,
        account: accountInfo,
        effectiveMint: parseTokenAccountData(accountInfo.data).mint,
      };
    }
  );
  return parsedSplAccounts.concat({
    pubkey: ownerAddress,
    account,
    effectiveMint: WRAPPED_SOL_MINT,
  });
}

export function parseTokenAccountData(data: Buffer): {
  mint: PublicKey;
  owner: PublicKey;
  amount: number;
} {
  let { mint, owner, amount } = ACCOUNT_LAYOUT.decode(data);
  return {
    mint: new PublicKey(mint),
    owner: new PublicKey(owner),
    amount,
  };
}

export const TOKEN_PROGRAM_ID = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
);

export async function getOwnedTokenAccounts(
  connection: any,
  publicKey: any
): Promise<Array<{ publicKey: any; accountInfo: any }>> {
  let filters = getOwnedAccountsFilters(publicKey);
  let resp = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
    filters,
  });
  return resp.map(
    ({ pubkey, account: { data, executable, owner, lamports } }) => ({
      publicKey: new PublicKey(pubkey),
      accountInfo: {
        data,
        executable,
        owner: new PublicKey(owner),
        lamports,
      },
    })
  );
}
export function getOwnedAccountsFilters(publicKey: any) {
  return [
    {
      memcmp: {
        offset: ACCOUNT_LAYOUT.offsetOf('owner'),
        bytes: publicKey.toBase58(),
      },
    },
    {
      dataSize: ACCOUNT_LAYOUT.span,
    },
  ];
}

// export function useMintInfos(): [
//   (
//     | {
//         [mintAddress: string]: {
//           decimals: number;
//           initialized: boolean;
//         } | null;
//       }
//     | null
//     | undefined
//   ),
//   boolean
// ] {
//   const connection = useConnection();
//   const [tokenAccounts] = useTokenAccounts();
//   const [allMarkets] = useAllMarkets();

//   const allMints = (tokenAccounts || [])
//     .map((account) => account.effectiveMint)
//     .concat(
//       (allMarkets || []).map((marketInfo) => marketInfo.market.baseMintAddress)
//     )
//     .concat(
//       (allMarkets || []).map((marketInfo) => marketInfo.market.quoteMintAddress)
//     );
//   const uniqueMints = [...new Set(allMints.map((mint) => mint.toBase58()))].map(
//     (stringMint) => new PublicKey(stringMint)
//   );

//   const getAllMintInfo = async () => {
//     const mintInfos = await getMultipleSolanaAccounts(connection, uniqueMints);
//     return Object.fromEntries(
//       Object.entries(mintInfos.value).map(([key, accountInfo]) => [
//         key,
//         accountInfo && parseTokenMintData(accountInfo.data),
//       ])
//     );
//   };

//   return useAsyncData(
//     getAllMintInfo,
//     tuple(
//       'getAllMintInfo',
//       connection,
//       (tokenAccounts || []).length,
//       (allMarkets || []).length
//     ),
//     { refreshInterval: _VERY_SLOW_REFRESH_INTERVAL }
//   );
// }
